.orgCard {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  transition: 0.2s all;
  cursor: pointer;
}

.orgCard:hover {
  background: var(--primary-5);
}
