.navItem {
  display: flex !important;

  transition: all 0.2s;

  white-space: nowrap;
  overflow: hidden;
  margin: 6px 0px !important;
  cursor: pointer;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 12px;
  margin: 4px;
  border-radius: 5px;
  width: 96%;
  border: 2px solid transparent;
}
.navItem:hover {
  background-color: var(--neutral-5);
}

.navItemActive {
  border: 2px solid var(--border);
  background-color: var(--neutral-10);
}

.navItemText {
  font-size: 1rem;
  text-overflow: ellipsis;
  flex-grow: 1;
  text-align: left;
  margin-left: 0.7rem;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
}

.navItemDisabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.marginBottomLarge {
  margin-bottom: 24px !important;
}
