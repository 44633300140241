.header {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 6px 8px;
  justify-content: space-between;
  border: 1px solid white;
  line-height: 29px;
}

.header:hover {
  border: 1px solid #594edd;
}
