.dropdownMenuItem {
  border-radius: 6px;
  transition: all 0.2s;
  font-size: 14px;
  padding: 4px 8px;
  cursor: pointer;
}

.dropdownMenuItem:hover {
  background: var(--primary-100) !important;
}

.dropdownMenuItem:hover .dropdownMenuItemText {
  color: var(--neutral-0) !important;
}
